import req from "../methods/req"
import method from '../methods/method'


// 规则配置列表
export const getRuleList = function({
	_this,
	data = {}
}) {
	return method.get_list({
		_this,
		url: '/manage/sink_frontline.rule/getRuleList',
		data
	})
}

// 获取规则列表统计
export const getListCount = function(data) {
	return req({
		url: '/manage/sink_frontline.rule/getListCount',
		...data,
	})
}

// 规则添加编辑
export const saveRuleInfo = function(data) {
	return req({
		url: '/manage/sink_frontline.rule/saveRuleInfo',
		...data,
	})
}

// 获取规则详情
export const getRuleDetail = function(data) {
	return req({
		url: '/manage/sink_frontline.rule/getRuleDetail',
		...data,
	})
}

// 规则禁用启用
export const changeRuleStatus = function(data) {
	return req({
		url: '/manage/sink_frontline.rule/changeRuleStatus',
		...data,
	})
}


// 规则删除
export const delRule = function(data) {
	return req({
		url: '/manage/sink_frontline.rule/delRule',
		...data,
	})
}

// 日报表详情
export const getDetail = function(data) {
	return req({
		url: '/manage/sink_frontline.report/getDetail',
		...data,
	})
}

// 头部统计
export const getTopCount = function(data) {
	return req({
		url: '/manage/sink_frontline.report/getTopCount',
		...data,
	})
}



// 获取日报表列表
export const getDayList = function({
	_this,
	data = {}
}) {
	return method.get_list({
		_this,
		url: '/manage/sink_frontline.report/getDayList',
		data
	})
}


// 获取月报表列表
export const getMonthList = function({
	_this,
	data = {}
}) {
	return method.get_list({
		_this,
		url: '/manage/sink_frontline.report/getMonthList',
		data
	})
}


// 人员选择
export const getUserSelect = function(data) {
	return req({
		url: '/manage/crm.common/getUserSelect',
		...data,
	})
}


// 删除配置
// export const getUserList = function(data) {
// 	return req({
// 		url: '/manage/sink_frontline.config/getUserList',
// 		...data,
// 	})
// }

export const getUserList = function({
	_this,
	data = {}
}) {
	return method.get_list({
		_this,
		url: '/manage/sink_frontline.config/getUserList',
		data
	})
}

// 保存
export const saveConfig = function(data) {
	return req({
		url: '/manage/sink_frontline.config/saveConfig',
		...data,
	})
}


//详情
export const getUserDetail = function(data) {
	return req({
		url: '/manage/sink_frontline.config/getUserDetail',
		...data,
	})
}

//详情
export const delConfig = function(data) {
	return req({
		url: '/manage/sink_frontline.config/delConfig',
		...data,
	})
}


//查看交接班结果
export const getMonthHandOverDetail = function(data) {
	return req({
		url: '/manage/sink_frontline.report/getMonthHandOverDetail',
		...data,
	})
}
export const getSeasonSelect = function(data) {
	return req({
		url: '/manage/sink_frontline.report/getSeasonSelect',
		...data,
	})
}




