<template>
	<div>
		<TableList :where='where' :columns="columns" :form_data_seo="form_data_seo"
			:get_table_list="this.get_table_list" :slot_table_list="['operation','status']" :is_page="false" rowKey="id"
			ref="list">
			<template slot="left_btn">
				<a-button class="float_left" v-auth="'change'" @click="add()" type="primary">添加套餐等级</a-button>
			</template>
			<template slot="status" slot-scope="data">
				
				<a-tag  :color="data.record.status == 0 ? config.color.error : config.color.primary">{{data.record.status==1?'启用':'禁用'}}</a-tag>
			</template>
			<template slot="operation" slot-scope="data">
				<a @click="edit(data.record,'add')">新增子类型</a>
				<a-divider type="vertical" />
				<a v-auth="'change'" @click="edit(data.record)">编辑</a>
				<a-divider type="vertical" />
				
				<a-popconfirm title="您确定要删除吗?"  v-auth="'delete'"
					@confirm="del(data.record)">
						<a v-auth="'change'" >删除</a>
				</a-popconfirm>
			
			</template>
		</TableList>
		<EditPop ref='EditPop' :title="edit_type?'编辑套餐等级':'添加套餐等级'" @handleSubmit="add_submit" :form_data="form_data"
			:visible.sync="add_visible">
		</EditPop>
	</div>
</template>

<script>
	// @ is an alias to /src
	import EditPop from "@/components/EditPop";
	import Form from "@/components/Form";
	import TableList from "@/components/TableList";

	import {
		getOrderMealCateList,
		getOrderMealCateListSelect,
		delOrderMealCate,
		saveOrderMealCate,getOrderMealCateDetail,
	} from "@/api/goods";

	import {
		message
	} from 'ant-design-vue'

	let columns = [
		{
			title: "套餐等级名称",
			dataIndex: "title",
		},
		{
				title: "ID",
				dataIndex: "id",
		
			},
			{
				title: "排序",
				dataIndex: "sort",
			
			},
		
		{
			title: "品项数量",
			dataIndex: "goods_num",
		},
		{
			title: "状态",
			dataIndex: "status",
			scopedSlots: {
				customRender: "status"
			},
		},
		{
			title: "说明",
			dataIndex: "remark",
		},


		{
			title: "操作",
			scopedSlots: {
				customRender: "operation"
			},
			fixed: 'right'
		},
	];

	

	export default {
		name: "Index",
		components: {
			EditPop,
			Form,
			TableList,
		},
		data() {
			return {
				edit_type: 1,
				get_table_list: getOrderMealCateList,
				submit_preprocessing: {
					array_to_string: ['department', 'customer_type']
				},
				config: this.$config,
				form: this.$form.createForm(this, {
					name: "advanced_search"
				}),
				columns,
				where: {
					type: 'list',
				},
				visible: false,
				add_visible: false,
				form_data_seo: {
					list: [
						{
							type: "text",
							name: "keyword",
							title: "套餐等级名称",
							placeholder: '请输入套餐等级名称',
							options: {}
						},
						{
							type: 'select',
							title: '状态',
							name: 'status',
							options: {},
							list: [{
									key: 1,
									value: "启用"
								},
								{
									key: 0,
									value: "禁用"
								},
							]
						},
					

					],
					...this.$config.form_data_seo
				},

				form_data: {
					title: '添加套餐等级',
					list: [{
							type: 'text',
							name: 'id',
							hidden: true,
							options: {
								initialValue: ''
							},
						},
						{
							type: 'text',
							name: 'title',
							title: '套餐等级名称',
							options: {
								initialValue: ''
							},
						},
						{
							type: 'cascader',
							name: 'pid',
							title: '上级套餐',
							options: {
								initialValue: []
							},
							list:[],
							replaceFields:{label:'title',value:'id',children:'children'},
						},
						{
							type: 'text',
							name: 'sort',
							title: '排序',
							options: {
								initialValue: ''
							},
						},
						{
							type: 'radio',
							name: 'status',
							title: '状态',
							list: [{
									key: 1,
									value: "启用"
								},
								{
									key: 0,
									value: "禁用"
								},
							],
							options: {
							initialValue: ''
							},
						},
						{
							type: 'text',
							name: 'remark',
							title: '套餐说明',
							options: {
								initialValue: ''
							},
						},
					]
				},

			};
		},
		async created() {
			
			
		},
		methods: {
			get_pid(){
				getOrderMealCateListSelect({data:{
					type:'select'
				}}).then(res=>{
					this.form_data.list[2].list = res.data.list
				})
			},
			edit(data,type = 'edit') {
				
				this.get_pid()
				
				getOrderMealCateDetail({data:{
					id:data.id
				}}).then(res=>{
					if(type == 'add'){
						this.form_data.title = "添加"
						this.form_data.list.forEach(item => {
							if(item.name=='pid'){
									item.options.initialValue=res.data.list.pid==0?[]:res.data.list.pids
							}else{
								item.options.initialValue ='';
							}
						})
					}else{
						this.form_data.title = "编辑"
						this.form_data.list.forEach(item => {
							if(item.name=='pid'){
								item.options.initialValue=res.data.list.pid==0?[]:res.data.list.pids
							}else{
								item.options.initialValue =res.data.list[item.name];
							}
						})
						
					}
				})
				
				this.$forceUpdate()
			    this.add_visible = true;
			},
			add_submit(e) {
				saveOrderMealCate({
					data: {...e.values,pid:e.values.pid?e.values.pid[e.values.pid.length-1]:0},
					info: true
				}).then(res => {
					this.edit_type = false
					this.add_visible = false
					this.$refs.list.get_list();
				})
			},
			add() {
				this.get_pid()
				this.edit_type = false
				this.add_visible = true
			},
			del(record) {
				delOrderMealCate({
					data: {
						id: record.id
					},
					info: true
				}).then(res => {
					this.$refs.list.get_list();
				})
			},
		}
	};
</script>

<style lang="less">
	@import url("../../assets/less/app.less");

	.drawer_title {
		padding: 15px 0;
	}

	.use_status {
		display: flex;
		align-items: center;

		.dot {
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background: @success-color;
			margin-right: 8px;
		}

		.red {
			background: @error-color;
		}
	}

	.input_item {
		display: flex;
		align-items: center;
		margin-top: 14px;

		.text {
			white-space: nowrap;
			font-size: 14px;
			margin-right: 4px;
		}
	}

	.tips {
		margin-top: 8px;
		color: @error-color;
		font-size: 14px;
	}

	.code_box {
		display: flex;
		align-items: center;

		div {
			flex: 1;
		}
	}
</style>