<template>
	<div>
		<div v-show="form_data_seo.list.length" :class="style_mode == 'table_list' ? 'page_content' : ''"
			style="padding-bottom:0">
			<Form class="ant-advanced-search-form" :form_data="form_data_seo" @handleSubmit="handleSubmit"
				@handleReset="handleReset" @get_date='get_date' @get_select_value="get_select_value" ref="form_seo"
				:chose_parent="chose_parent">
			</Form>
		</div>
		<slot name="form_after"></slot>
		<div :class="style_mode == 'table_list' ? 'page_content' : ''">
			<slot name="before"></slot>
			<div class="top_btn">
				<slot name="left_btn"></slot>
				<div class="flex"></div>
				<div class="right_btn">
					<slot name="right_btn"></slot>
					<a-dropdown :trigger="['click']" v-if="columns_setting_show">
						<a-button class="a_btn" type="primary" @click="screen_columns.show = true">
							<a-icon type="setting-o" />
						</a-button>
						<!-- <template #overlay>
							<a-menu>
								<a-menu-item key="0" @click="screen_columns.show = true">设置显示字段</a-menu-item>
							</a-menu>
						</template> -->
					</a-dropdown>
				</div>
			</div>
			<slot name="table_before"></slot>
			<a-table :rowKey="rowKey" :scroll="scroll" :columns="real_columns" :pagination.sync="pagination"
				:dataSource="list" @showSizeChange="showSizeChange" @change="handleTableChange" :loading="loading"
				:expandRowByClick="expandRowByClick" :rowSelection="rowSelection"
				@expandedRowsChange="expandedRowsChange($event)" :locale="{emptyText}">
				<div :slot="expandedRowRender ? 'expandedRowRender' : ''" slot-scope="record">
					<slot name="expandedRowRender" :record="record"></slot>
				</div>
				<template v-for="item in slot_table_list" :slot="item" slot-scope="text,record,index">
					<slot :name="item" :text="text" :record="record" :index="index"></slot>
				</template>
			</a-table>
			<slot name="after"></slot>
		</div>
		<!-- 表格显示字段设置弹窗 -->
		<a-modal title="字段设置" v-model:visible="screen_columns.show" @ok="screen_columns_ok" width="800px"
			class="screen_columns_modal">
			<div class="content">
				<div class="left">
					<a-checkbox :checked="checkAll" :indeterminate="indeterminate" @change="onCheckAllChange"
						class="allCheckBox">
						全选
					</a-checkbox>
					<a-checkbox-group v-model:value="screen_columns.checked" @change="screen_columns_checked_change"
						class="a-checkbox-group">
						<div v-if="cate_list && cate_list.length">
							<div v-for="(cateV,cateK) in cate_list">
								<template v-if="cateV!='none'">

									<div class="cate_title">{{cateV}}</div>
									<div class="checkboxGroup">
										<a-checkbox v-for="(item,index) in columns" v-if="item.cate_name == cateV"
											class="columns_checkbox" :key="item.dataIndex" :value="item.dataIndex"
											@change="checkbox_change">{{ item.title }}
										</a-checkbox>
									</div>
								</template>

							</div>
							<div v-if="columns.findIndex(item=>{
								return !item.cate_name
							}) > -1">
								<div class="cate_title">其他</div>
								<div class="checkboxGroup">
									<a-checkbox v-for="(item,index) in columns" v-if="!item.cate_name"
										class="columns_checkbox" :key="item.dataIndex" :value="item.dataIndex"
										@change="checkbox_change">{{ item.title }}
									</a-checkbox>
								</div>
							</div>
						</div>
						<div v-else>
							<a-checkbox v-for="(item,index) in columns" class="columns_checkbox" :key="item.dataIndex"
								:value="item.dataIndex" @change="checkbox_change">{{ item.title }}
							</a-checkbox>
						</div>
					</a-checkbox-group>
				</div><!-- left -->
				<div class="right">
					<div class="title">当前选定的字段</div>
					<div v-for="(item,index) in columns">
						<div class="item" v-if="screen_columns.checked.findIndex(v=>{return v == item.dataIndex}) > -1">
							<template v-if="item.cate_name!='none'">
								<div class="text">{{item.title}}</div>
								<a-icon type="close-o" class="close" @click="screen_columns_del(item)" />
							</template>

						</div>
					</div>
				</div><!-- right -->
			</div><!-- screen_columns_modal -->
		</a-modal>
	</div>
</template>

<script>
	import Form from "@/components/Form";
	import props_table_list from './props/table_list'
	export default {
		name: "TableList",
		components: {
			Form
		},
		props: {
			...props_table_list,
			columns_setting_show: false, // 是否显示表格设置按钮（其中有设置表格显示字段
			cate_list: {
				type: Array,
				default: () => {
					return []
				}
			}, // 字段设置弹窗中的字段分类
			emptyText: {
				type: String,
				default: '暂无数据'
			},
			get_table_list: {
				type: Function,
				default: () => {

				}
			},

			default_screen_all_checked: {
				type: Boolean,
				default: true
			}, // 字段显示设置弹窗是否在一开始就选中所有字段
			chose_parent: {
				type: Boolean,
				default: false,
			},
			chose_parent: {
				type: Boolean,
				default: false,
			},
			is_page: {
				type: Boolean,
				default: true,
			},
			where: {
				type: Object,
				default () {
					return {}
				}
			},
		},
		data() {
			return {
				page: 1,
				pagination: {
					...this.$config.pagination
				},
				loading: false,
				list: [],
				seo_data: {},
				fixed_seo_data: {},
				real_columns: [], // 真正在页面上渲染出来的columns，由于有时需要对columns进行操作（如变换顺序或者显示隐藏列），因此我需要将父级传过来的columns填充至此以便我二次操作
				screen_columns: {
					show: false, // 设置显示字段弹窗是否显示
					checked: [], // 选中的要显示的字段
				},
			};
		},
		watch: {
			columns: {
				handler(newValue, oldValue) {
					this.real_columns = newValue;
					// 是否默认在字段显示弹窗中先全部选中
					if (this.default_screen_all_checked) {
						this.screen_columns.checked = newValue.map(item => {
							return item.dataIndex;
						})
					}
				},
				immediate: true,
				deep: true
			},
			is_page: {
				handler(newValue, oldValue) {
					this.is_page = newValue;
					if (!this.is_page) {
						this.pagination = false;
					} else {
						this.pagination = {
							...this.$config.pagination
						}
					}

				},
				immediate: true,
				deep: true
			},
			get_table_list: {
				handler(newValue, oldValue) {
					this.get_table_list = newValue ? newValue : oldValue
				},

			},


		},
		created() {
			if (!this.is_page) {
				this.pagination = false;
			}
			if (this.is_created_get_list) this.get_list();
		},
		destroyed() {
			this.$store.commit('set', {
				table_list_seo_data: {}
			})
		},
		methods: {
			// 字段显示设置弹窗中右侧已选中字段点击了删除
			screen_columns_del(item) {
				let index = this.screen_columns.checked.findIndex(v => {
					return v == item.dataIndex;
				})
				this.screen_columns.checked.splice(index, 1);
			},
			// 单个复选框选中状态发生改变时
			checkbox_change(e) {},
			// 全选复选框选中状态发生改变时
			onCheckAllChange(e) {
				this.screen_columns.checked = e.target.checked ? this.columns.map(item => {
					return item.dataIndex
				}) : [];
			},
			// 设置字段显示弹窗中的复选框组触发时
			screen_columns_checked_change(e) {
				console.log('this.screen_columns.checked', this.screen_columns.checked)
			},
			// 该方法主要由父级进行调用，将字段显示设置筛选项与列表中显示的列进行同步
			set_columns() {
				let has_selected_columns = [];
				this.columns.map(item => {
					this.screen_columns.checked.map(v => {
						if (item.dataIndex == v) {
							has_selected_columns.push(item);
						}
					})
				})
				this.real_columns = has_selected_columns;
			},
			// 显示字段设置弹窗中点下了确认按钮
			screen_columns_ok() {
				if (!this.screen_columns.checked.length) {
					this.$message.error('请至少选择一项字段');
					return;
				}

				this.screen_columns.show = false;
				this.set_columns();
				// 传入后端的field顺序需要和columns一致
				let field_checkeds = [];
				this.columns.map(item => {
					this.screen_columns.checked.map(vo => {
						if (item.dataIndex === vo) {
							field_checkeds.push(vo);
						}
					})
				})

				// 将勾选中的需要让后端查询的数据传入固定seo_data中
				this.fixed_seo_data.field = field_checkeds.toString();
				this.get_list();
				this.$emit('screen_columns_ok', {
					checkeds: this.screen_columns.checked, // 字段显示设置弹窗中目前勾选中了的字段
					columns: this.real_columns, // 列表中目前显示出来的列
				});
			},
			get_date(e, item) {
				this.$emit('get_date', e)

			},
			rest_get_list() {
				this.pagination.current = 1;
				this.pagination.page = 1;
				this.list = [];
				this.get_list();
			},
			handleTableChange(pagination) {
				this.pagination.pageSize = pagination.pageSize;
				this.pagination.page = pagination.current;
				this.page = pagination.current;
				this.get_list();
			},
			showSizeChange(pagination) {

			},
			handleReset(data) {

				this.list = [];
				this.seo_data = data;
				this.fixed_seo_data.keyword = ''
				this.page = 1;
				this.pagination.page = 1
				this.get_list();
				this.$emit('changeStatus', this.seo_data)
				this.$store.commit('set', {
					table_list_seo_data: this.seo_data
				})
			},
			// 提交前 表单参数预处理，也可以主动调用在不提交表单的情况下获取搜索项的值
			handleFormData(e) {
				let data = e;
				if (this.submit_preprocessing != null) {
					for (let key in this.submit_preprocessing) {
						if (key == 'array_to_string') {
							if (Array.isArray(this.submit_preprocessing[key])) {
								for (let index in data) {
									if (this.submit_preprocessing[key].indexOf(index) > -1) {
										if (Array.isArray(data[index])) {
											data[index] = data[index].join(',');
										}
									}
								}
							}
						}
					}
				}
				return data;
			},
			get_select_value(e) {
				this.$emit('get_select_value', e)
			},
			handleSubmit(e) {
				this.seo_data = this.handleFormData(e.values);
				this.page = 1;
				if (this.is_page) {
					this.pagination.current = 1;
					this.pagination.page = 1;
				}
				this.list = [];
				this.get_list();

				this.$store.commit('set', {
					table_list_seo_data: this.seo_data
				})
				this.$emit('changeStatus', this.seo_data)

			},

			set_data(key, value) {
				this[key] = value;
			},

			expandedRowsChange(e) {
				this.$emit('expandedRowsChange', e)
			},
			setFieldsValue(data) {
				this.$refs.form_seo.setFieldsValue(data)
			},
			list_after(res) {
				this.$emit('list_after', res)
			},
			get_list(data = this.seo_data) {
				if (!this.is_page) {
					this.loading = true;
				}
				this.get_table_list({
					_this: this,
					data: {
						...this.fixed_seo_data,
						...data,
						...this.where,
						...this.pagination
					}
				}).then(res => {
					if (!this.is_page) {
						if (res.res) {
							this.list = res.res.data.list;
						} else {

							this.list = res.data.list;
						}
					}
					this.loading = false;
					this.list_after(res);

				})
			},

			//更新导出数据时使用----------------
			getNowData(data) {
				if (this.list.length > 0) {
					for (let i = 0; i < this.list.length; i++) {
						for (let k = 0; k < data.length; k++) {
							if (this.list[i].id == data[k].id) {
								Object.assign(this.list[i], data[k]);
							}
						}
					}
				}
			},
			//获取列表中正在进行的数据
			getListDataProgress() {
				return new Promise((resolve, reject) => {
					let ids = []
					for (let i = 0; i < this.list.length; i++) {
						if (this.list[i].status == 2 || (this.list[i].status > -1 && this.list[i].status < 1)) {
							ids.push(this.list[i].id)
						}
					}
					resolve({
						ids: ids
					})
				})
			},

		},
		computed: {
			checkAll() {
				return this.screen_columns.checked.length == this.columns.length;
			},
			indeterminate() {
				return !!this.screen_columns.checked.length && this.screen_columns.checked.length < this.columns.length
			},
		},
	};
</script>

<style lang="less" scoped="scoped">
	.cate_title {
		margin-left: 8px;
		margin-bottom: 8px;
		font-weight: bold;
	}

	.checkboxGroup {
		display: flex;
		flex-wrap: wrap;
	}

	.columns_checkbox {
		width: calc(25% - 8px);
		margin-left: 8px;
		margin-bottom: 12px;
	}

	.screen_columns_modal {
		&/deep/.ant-modal-body {
			padding: 0;
		}

		.content {
			display: flex;
			justify-content: space-between;
			box-sizing: border-box;

			.allCheckBox {
				margin-left: 8px;
				padding-bottom: 8px;
				margin-bottom: 12px;
				border-bottom: 1px solid #e6e6e6;
				width: 100%;
			}

			.title {
				padding: 10px 0 10px 18px;
				margin: 0;
				font-size: 12px;
				color: #8c95a8;
			}

			.left {
				padding: 20px;
				width: 70%;

				.a-checkbox-group {
					width: 100%;
				}
			}

			.right {
				border-left: 1px solid #e6e6e6;
				flex: 1;

				.item {
					padding: 8px;
					// cursor: move;
					display: flex;
					justify-content: space-between;
					align-items: center;

					.close {
						cursor: pointer;
						padding: 3px;
					}

					&:hover {
						background: #eaf2ff;
					}
				}
			}
		}
	}
</style>