import req from "../methods/req"
import method from '../methods/method'

export const login = function (data){
    return req({
        url:'/manage/api.login/index',
        login:true,
        data
    })
}


export const user_index = function ({
	_this,
	data
}){
    return method.get_list({
		_this,
        url:'/manage/crm.user/getUserList',
        data
    })
}


// 获取人员档案数据
export const getUserData = function (data){
    return req({
        url:'/manage/crm.user/getUserData',
        data
    })
}


// 添加编辑档案数据
export const modifyUser = function (data){
    return req({
        url:'/manage/crm.user/modify',
        ...data
    })
}

// 组织架构列表
export const getFramework = function (data){
    return req({
        url:'/manage/crm.user/getFramework',
        ...data
    })
}

// 组织架构修改
export const changeFramework = function (data){
    return req({
        url:'/manage/crm.user/changeFramework',
        ...data
    })
}


// 组织架构删除
export const delFramework = function (data){
    return req({
        url:'/manage/crm.user/delFramework',
        ...data
    })
}

// 组织架构列表
export const postList = function (data){
    return method.get_list({
        url:'/manage/crm.user/postList',
        ...data
    })
}

//获取岗位多级
export const getPostList = function (data){
    return req({
        url:'/manage/crm.user/getPostList',
        ...data
    })
}

// 组织架构修改
export const changePost = function (data){
    return req({
        url:'/manage/crm.user/changePost',
        ...data
    })
}


// 组织架构删除
export const deletePost = function (data){
    return req({
        url:'/manage/crm.user/deletePost',
        ...data
    })
}



// 考勤规则
export const getRule = function (data){
    return req({
        url:'/manage/crm.user/getRule',
        ...data
    })
}

export const getAuthList = function (data) {
	return req({
		url: '/manage/crm.user/getAuthList',
		...data
	})
}

export const updateDepartment = function (data) {
	return req({
		url: '/manage/crm.user/updateDepartment',
		...data
	})
}

export const transferFramework = function (data) {
	return req({
		url: '/manage/crm.user/transferFramework',
		...data
	})
}


export const cancelBinding = (data) => req({ url: '/manage/crm.user/cancelBinding', ...data })
export const setRegion = (data) => req({ url: '/manage/crm.user/setRegion', ...data })

export const validFramework = (data) => req({ url: '/manage/crm.user/validFramework', ...data })
export const category = (data) => req({ url: '/manage/attendance.intelligent_scheduling/category', ...data })
export const userPasswordInit = (data) => req({ url: '/manage/crm.user/userPasswordInit', ...data })


export const saveFaceSetting = (data) => req({ url: '/manage/crm.user/saveFaceSetting', ...data })

export const getFaceSetting = (data) => req({ url: '/manage/crm.user/getFaceSetting', ...data })

