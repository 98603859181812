<template>
	<PopTableList :form_data_seo='form_data_seo_pop' ref='PopTableList' :zIndex="2000" :columns="pop_columns"
		:visible.sync="user_visible" :rowSelection="rowSelection" :get_table_list="get_table_list"
		:submit_preprocessing="submit_preprocessing" @submit="submit" rowKey="id">

	</PopTableList>
</template>

<script>
	import PopTableList from './PopTableList'
	import {
		getGoodsSelect
	} from "@/api/cost.js";
	import props_pop from './props/pop'
	import table_pop from './props/table'
	const columns = [

		{
			title: "品项名称",
			dataIndex: "goods_title"
		},
	]

	export default {
		name: 'SelectUser',
		components: {
			PopTableList,
		},
		props: {
			...props_pop,
			...table_pop,
			chooseType: String,
		},

		data() {
			return {
				selectedRowKeys: [],
				selectedRows: [],
				user_visible: false,
				get_table_list: getGoodsSelect,
				pop_columns: columns,
				submit_preprocessing: {},
				form_data_pop: {
					title: '请选择品项'
				},
				form_data_seo_pop: {
					...this.$config.form_data_seo,
					list: [{
						type: "text",
						name: "keyword",
						title: "关键词",
						placeholder: "品项关键词",
						options: {}
					}]
				},
				select_user: {},
				temp_list: [],
				// selectedInfo:[]
			}
		},
		computed: {

			rowSelection() {
				const {
					selectedRowKeys,
					selectedRows,
					temp_list
				} = this;
				return {
					selectedRowKeys,
					selectedRows,
					onChange: (selectedRowKeys, selectedRows) => {
						let prevSelectedRows = this.selectedRows;
						this.selectedRowKeys = selectedRowKeys;
						this.uids = selectedRowKeys.join(',')
						selectedRows.forEach(it => {
							if (this.selectedRowKeys.indexOf(it.id) > -1) {
								this.temp_list.push(it)
							}
						})
					},
					onSelect: (record, selected, selectedRows, nativeEvent) => {
						if (selected) {
							if (this.temp_list.length == 0) {
								this.temp_list = selectedRows
							} else {
								selectedRows.forEach(it => {
									if (this.selectedRowKeys.indexOf(it.id) > -1) {
										this.temp_list.push(it)
									}
								})
							}

						} else {
							let index = -1
							this.temp_list.forEach((it, ind) => {
								if (it.id == record.id || it.goods_id == record.id) {
									index = ind
								}
							})
							if(index>-1){
								this.temp_list.splice(index, 1)
							}
						}

					},
					type: this.chooseType ? this.chooseType : 'radio',
					hideDefaultSelections: true,
				};
			},
		},
		watch: {
			visible(new_value) {
				this.user_visible = new_value
			},
			user_visible(new_value) {
				this.$emit('update:visible', new_value)
			},
		},
		created() {

		},
		methods: {
			submit() {
				let arr =[]
				let arrId =[]
				this.temp_list.forEach(it=>{
					if(this.selectedRowKeys.indexOf(it.id)>-1&&arrId.indexOf(it.id)<0){
						arrId.push(it.id)
						arr.push(it)
					}
				})
				this.$emit('submit', {
					data: arr
				})
			}
		}
	}
</script>