<template>
    <div class="transfer">
        <div class="tree">
            <div class="seo">
                <a-input-search @change="onSearchChange" placeholder="请输入搜索内容" />
            </div>
            <a-tree class="tree_list" checkable @expand="onExpand" :checkStrictly="true" :expandedKeys="expandedKeys"
                :autoExpandParent="autoExpandParent" v-model="checkedKeys" @select="onSelect"
                :selectedKeys="selectedKeys" :treeData="seo_value ? treeDataSearch :treeData" />
        </div>
        <div class="btns">
            <a-button class="btn" @click="to_left">
                <a-icon type="left" />
            </a-button>
            <a-button class="btn" @click="to_right">
                <a-icon type="right" />
            </a-button>
        </div>
        <div class="tree">
            <div class="seo">
                <a-input-search @change="onSearchChangeTwo" placeholder="请输入搜索内容" />
            </div>
            <a-tree class="tree_list" checkable @expand="onExpand" :expandedKeys="expandedKeys"
                :autoExpandParent="autoExpandParent" v-model="checkedKeysTwo" @select="onSelectTwo"
                :selectedKeys="selectedKeysTwo" :treeData="seo_value_two ? treeDataSearchTwo  :treeDataTwo" />
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            treeData: {
                type: Array,
                default: () => {
                    return [];
                }
            },
            value: {
                type: Array,
                default: () => {
                    return [];
                }
            }
        },
        data() {
            return {
                // :[],
                treeDataSearch: [],
                seo_value: '',
                treeDataSearchTwo: [],
                seo_value_two: '',
                expandedKeys: [],
                selectedKeys: [],
                autoExpandParent: true,
                checkedKeys: [],
                treeDataTwo: [],
                selectedKeysTwo: [],
                checkedKeysTwo: [],
            }
        },
        watch: {
            value: {
                immediate: true,
                handler(value) {
                    let treeData = this.set_list_disabled(this.treeData, value, true);
                    this.checkedKeys = this.get_Selected_list(value);
                    this.selectedKeys = this.get_Selected_list(value);
                    this.$emit('update:treeData', treeData)
                    let treeDataTwo = this.get_select_dom(treeData, value);
                    this.treeDataTwo = this.get_duplicate_removal(treeDataTwo);
					this.$forceUpdate()
				}
            }
        },
        methods: {
            to_left() {
                let treeData = this.set_list_disabled(this.treeData, this.checkedKeysTwo, false);
                this.$emit('update:treeData', treeData)
                this.treeDataSearch = this.set_list_disabled(this.treeDataSearch, this.checkedKeys.checked, false);
                let treeDataTwo = [...this.treeDataTwo];
                treeDataTwo = this.clear_item(treeDataTwo, this.checkedKeysTwo)
                this.treeDataSearchTwo = this.clear_item([...this.treeDataSearchTwo], this.checkedKeysTwo);
                this.treeDataTwo = this.get_duplicate_removal(treeDataTwo);
                this.checkedKeysTwo = [];
                this.$emit('change', this.get_value_item());
            },
            clear_item(list, keys) {
                for (let i = list.length - 1; i >= 0; i--) {
                    for (let j = keys.length - 1; j >= 0; j--) {
                        if (list[i].key.indexOf(keys[j]) > -1) {
                            list.splice(i, 1);
                            break;
                        }
                    }
                }
                return list;
            },
            onSearchChange(e) {
                let value = e.target.value;
                this.seo_value = value;
                if (value) {
                    let searchList = this.seo_title(this.treeData, value);
                    this.treeDataSearch = this.get_duplicate_removal(searchList)
                } else {
                    this.treeDataSearch = [];
                }
            },
            onSearchChangeTwo(e) {
                let value = e.target.value;
                this.seo_value_two = value;
                if (value) {
                    let searchList = this.seo_title(this.treeDataTwo, value);
                    this.treeDataSearchTwo = this.get_duplicate_removal(searchList)
                } else {
                    this.treeDataSearchTwo = [];
                }
            },
            get_Selected_list(value){
                let selectedData = this.get_select_dom(this.treeData, value)
                let valueData = []
                selectedData.forEach(item=>{
                    valueData.push(item.key)
                })
                return valueData
            },
            //名称去重
            get_duplicate_removal(listSearch){
                let oldData = []
                let arrData = []
                listSearch.forEach(item=>{
                    let pids = item.type +'_'+ item.id
                    if(oldData.indexOf(pids) < 0){
                        oldData.push(pids)
                        arrData.push(item)
                    }
                })
                return arrData
            },
            seo_title(list, value) {
                let arr = [];
                list.forEach(item => {
                    let new_item = {}
                    let new_item_sub = [];
                    if (item.title.indexOf(value) > -1) {
                        new_item = {
                            ...item
                        }
                        new_item.children = [];
                    }
                    if (Array.isArray(item.children)) {
                        new_item_sub = this.seo_title(item.children, value)
                    }
                    if (new_item.key) arr.push(new_item);
                    new_item_sub.forEach(new_item => {
                        if (new_item.key) arr.push(new_item);
                    });
                });
                return arr;
            },
            to_right() {
                let checkedKeys = Array.isArray(this.checkedKeys) ? this.checkedKeys : this.checkedKeys.checked;
                let treeDataTwo = this.get_select_dom(this.treeData, checkedKeys);
                this.treeDataTwo = this.get_duplicate_removal(treeDataTwo);
                let treeData = this.set_list_disabled(this.treeData, checkedKeys, true);
                this.$emit('update:treeData', treeData)
                this.treeDataSearch = this.set_list_disabled(this.treeDataSearch, checkedKeys, true);
                this.$emit('change', this.get_value_item());
            },

            get_value_item() {
                let arr = [];
                this.treeDataTwo.forEach(res => {
                    arr.push(res.key)
                })
                return arr;
            },
            set_list_disabled(list, keys, disabled = false) {
                let arr = [];
                for (let i = 0; i < list.length; i++) {
                    let item = {
                        ...list[i]
                    };
                    let sub_itme = [];
                    for (let j = 0; j < keys.length; j++) {
                        if (list[i].key.indexOf(keys[j]) > -1) {
                            item.disabled = disabled;
                        }
                    }
                    if (Array.isArray(list[i].children) && list[i].children.length) {
                        item.children = this.set_list_disabled(list[i].children, keys, disabled)
                    }
                    arr.push(item)
                }
                return arr;
            },
            get_select_dom(list, keys) {
                let arr = [];
                for (let i = 0; i < list.length; i++) {
                    let item = {};
                    let sub_itme = [];
                    for (let j = 0; j < keys.length; j++) {
                        if (list[i].key.indexOf(keys[j]) > -1) {
                            item = {
                                ...list[i]
                            };
                            if (Array.isArray(item.children)) item.children = [];
                            break;
                        }
                    }
                    if (Array.isArray(list[i].children) && list[i].children.length) {
                        sub_itme = this.get_select_dom(list[i].children, keys)
                    }
                    item.disabled = false;
                    if (item.key) arr.push(item);

                    sub_itme.forEach(res => {
                        res.disabled = false;
                        if (res.key) arr.push(res);
                    })
                }
                return arr;
            },
            onExpand(expandedKeys) {
                this.expandedKeys = expandedKeys;
                this.autoExpandParent = false;
            },
            onCheck(checkedKeys) {
                this.checkedKeys = checkedKeys;
            },
            onSelect(selectedKeys, info) {
                this.selectedKeys = selectedKeys;
            },
            onSelectTwo(selectedKeys) {
                this.selectedKeysTwo = selectedKeys;
            },
        }
    }
</script>

<style lang="less" scoped>
    @import "../assets/less/base.less";

    .transfer {
        display: flex;
        align-items: center;

        .tree {
            border: 1px solid @border_color;
            position: relative;
            display: inline-block;
            width: 320px;
            height: 400px;

            vertical-align: middle;
            border-radius: 4px;

            .tree_list {
                overflow: auto;
                height: 350px;
            }
        }

        .seo {
            padding: 4px;
        }

        .btns {
            display: flex;
            flex-direction: column;
            margin: 0 10px;

            .btn {
                margin: 3px 0;
            }

        }
    }
</style>>
