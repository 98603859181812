<template>
	<div>
		<TableList @changeStatus='changeStatus' :form_data_seo.sync="form_data_seo" :columns="columns_list"
			:get_table_list="this.get_table_list"
			:slot_table_list="['operation','status','rule_name','use_num','execute_end_time','pass_status_name2']"
			:submit_preprocessing="submit_preprocessing" ref="list">
			<template slot="form_after">
				<div class='page_content_top page_content'>
					<div v-for='item in top_count'>
						<a-statistic :title="item.label" :value="item.value" />
					</div>
				</div>
			</template>
			<template slot="before">
				<div class="table_before">
					<div class="left">
						<a-tabs type="card" @change="tab_change" class='tab_info'>
							<a-tab-pane :key="0" tab="日统计"></a-tab-pane>
							<a-tab-pane :key="1" tab="月统计"></a-tab-pane>
						</a-tabs>
					</div>
					<div class="right_btn">
						<a @click="report()">
							<a-button class="a_btn">导出</a-button>
						</a>
					</div>
				</div>
			</template>
			<template slot="rule_name" slot-scope="data">
				<p>
					<span>
						<a @click='see_rule(data.record)'>
							{{data.record.rule_name}}
						</a>
					</span>
				</p>
			</template>
			<template slot="use_num" slot-scope="data">
				<p>
					<span>
						<a @click='see_user(data.record)'>
							{{data.record.use_num}}
						</a>
					</span>
				</p>
			</template>
			<template slot="execute_end_time" slot-scope="data">
				<p>
					<span>
						{{data.record.execute_start_time}}~{{data.record.execute_end_time}}
					</span>
				</p>
			</template>
			<template slot="operation" slot-scope="data">
				<p>
					<span>
						<a @click='get_details(data.record)'>
							查看
						</a>
					</span>
				</p>
			</template>
			<template slot="pass_status_name2" slot-scope="data">
				<p>
					<span v-if='data.record.is_handover==1'>
						<a @click='get_pass_details(data.record)'>
							{{data.record.handover_status}}
						</a>
					</span>
					<span v-else>
							{{data.record.handover_status}}
					</span>
				</p>
			</template>
			
			
		</TableList>

		<supInfo ref='supInfo' :visible.sync="drawer_visible">
		</supInfo>
		
		<passInfo ref='passInfo' :visible.sync="passInfo_visible">
		</passInfo>
		

		<a-modal title="查看规则" :maskClosable='true' :visible="see_visible" @cancel='see_visible=false'
			@ok='see_visible=false'>
			<div>
	<p class='info_item'><span>规则名称：</span><span>{{see_info.name}}</span></p>
				<p class='info_item'><span>是否启用晚班：</span><span>{{see_info.is_open_night?'是':'否'}}</span></p>
				<p class='info_item'><span>是否启用淡旺季：</span><span>{{see_info.is_busy_season?'是':'否'}}</span></p>
				<p class='info_item' v-if='see_info.is_busy_season'><span>旺季时间：</span><span>{{see_info.busy_season_time}}月</span></p>
				<p class='info_item' v-if='see_info.is_busy_season'><span>旺季白班合格时长：</span><span>{{see_info.day_pass_time}}小时</span></p>
				<p class='info_item' v-if='see_info.is_busy_season'><span>旺季夜班合格时长：</span><span>{{see_info.night_pass_time}}小时</span></p>

				<p class='info_item'><span>淡季白班合格时长：</span><span>{{see_info.day_pass_time}}小时</span></p>

				<p class='info_item'><span>淡季夜班合格时长：</span><span>{{see_info.night_pass_time}}小时</span></p>
				<p class='info_item' v-if='see_info.is_busy_season'>
					<span>旺季白班执行时间：</span><span>{{see_info.busy_day_start_time+'~'+see_info.busy_day_end_time}}</span>
				</p>
				<p class='info_item'>
					<span>淡季白班执行时间：</span><span>{{see_info.day_start_time+'~'+see_info.day_end_time}}</span>
				</p>

				<p class='info_item'>
					<span>晚班执行时间：</span><span>{{see_info.night_start_time+'~'+see_info.night_end_time}}</span>
				</p>
				<p class='info_item'><span>节假日打卡：</span><span>{{see_info.is_join_record_holiday?'记录':'不记录'}}</span></p>
				<p class='info_item'><span>月应合格天数：</span><span>{{see_info.pass_num}}天</span></p>

				<p class='info_item'><span>晚班应执行天数：</span><span>{{see_info.night_pass_num}}天</span></p>

				<p class='info_item'><span>免执行天数：</span><span>{{see_info.unneed_day}}天</span></p>
				<p class='info_item'><span>交接班打卡：</span><span>{{see_info.is_need_handover?'是':'否'}}</span></p>



				<p class='info_item'><span>创建时间：</span><span>{{see_info.create_time}}</span></p>

				<p class='info_item'><span>状态：</span><span>{{see_info.status==1?'执行中':'禁用中'}}</span></p>
			</div>
		</a-modal>
	</div>
</template>
<script>
	import EditPop from "@/components/EditPop";
	import Form from "@/components/Form";
	import TableList from "@/components/TableList";
	import supInfo from "@/views/supervision/supInfo.vue";
	import passInfo from "@/views/supervision/passInfo.vue";
	
	import {
		message
	} from 'ant-design-vue';
	import {
		getTopCount,
		getDayList,
		getMonthList,
		getRuleDetail,getSeasonSelect
	} from "@/api/supervision";

	let columns = [{
			title: "日期",
			dataIndex: "visit_date",
		},
		{
			title: "姓名",
			dataIndex: "username",
		}, {
			title: "规则名称",
			dataIndex: "rule_name",
			scopedSlots: {
				customRender: "rule_name"
			}
		},
		{
			title: "季节性高峰期",
			dataIndex: "busy_season_status",
		},
		{
			title: "开始时间",
			dataIndex: "start_time",
		},
		{
			title: "最后结束时间",
			dataIndex: "end_time",
		},
		{
			title: "白班执行时长",
			dataIndex: "total_day_time",
		},
		{
			title: "晚班执行时长",
			dataIndex: "total_night_time",
		},
		{
			title: "累计执行时长",
			dataIndex: "total_time",
		},
		
		
		
		{
			title: "操作",
			scopedSlots: {
				customRender: "operation"
			}
		},
	];

	let columns_month = [{
			title: "月份",
			dataIndex: "visit_month",
		}, {
			title: "姓名",
			dataIndex: "username",
		},
		{
			title: "部门",
			dataIndex: "depart",
		},
		{
			title: "季节性高峰期",
			dataIndex: "busy_season_status",
		},
		{
			title: "工作日天数",
			dataIndex: "work_day",
		},
		{
			title: "应执行天数",
			dataIndex: "need_total_visit",

		},
		{
			title: "应合格天数",
			dataIndex: "need_pass_num",

		},

		{
			title: "白班应执行天数",
			dataIndex: "need_pass_day_num",

		},
		{
			title: "晚班应执行天数",
			dataIndex: "need_pass_night_num",
		
		},
		{
			title: "白班实际执行天数",
			dataIndex: "actuality_visit_day_num",
		},
		{
			title: "晚班实执行天数",
			dataIndex: "actuality_visit_night_num",
		},
		{
			title: "白班合格天数",
			dataIndex: "pass_day_num",
		},
		{
			title: "晚班合格天数",
			dataIndex: "pass_night_num",
		},
		{
			title: "总合格天数",
			dataIndex: "pass_total_num",
		},
		{
			title: "是否合格",
			dataIndex: "status_name",
		},
		{
			title: "交接班合格状态",
			dataIndex: "pass_status_name2",
			scopedSlots: {
				customRender: "pass_status_name2"
			}
		},
		
	];

	export default {
		name: "gpoodIndex",
		components: {
			EditPop,
			Form,
			TableList,
			supInfo,passInfo
		},
		data() {
			return {
				type:1,
				tab_index: 0,
				see_info: {},
				see_visible: false,
				drawer_visible: true,
				passInfo_visible: false,
				where: {
					rule_id: ''
				},
				top_count: [],
				get_table_list: getDayList,
				columns,
				columns_month,
				columns_list: columns,
				visible: false,
				form_data_seo: {
					list: [{
							type: 'text',
							name: 'keyword',
							placeholder: '姓名、手机号、工号',
							title: '名称',
						},
						{
							type: "tree-select",
							name: "department",
							title: "部门",
							options: {},
							treeData: [],
							multiple: true
						},
						{
							type: 'select',
							name: 'day_pass_status',
							placeholder: '请选择',
							title: '白班状态',
						
							list: [{
									key: 0,
									value: '全部'
								},{
									key: -1,
									value: '白班不合格'
								}, {
									key: 1,
									value: '白班合格'
								},
							],
						},
							
						{
							type: 'select',
							name: 'night_pass_status',
							placeholder: '请选择',
							title: '晚班状态',
							list: [
								{
									key: 0,
									value: '全部'
								},
								{
									key: -1,
									value: '晚班不合格'
								}, {
									key: 1,
									value: '晚班合格'
								},
								
							],
						},
						{
							type: 'select',
							name: 'busy_season_status',
							placeholder: '请选择',
							title: '季节性高峰期',
							list: [
								// {
								// 	key: 0,
								// 	value: '全部'
								// },
								// {
								// 	key: 1,
								// 	value: '旺季'
								// }, {
								// 	key: -1,
								// 	value: '淡季'
								// },
								// {
								// 	key: 1,
								// 	value: '无'
								// },
								
							],
						},
						{
							type: 'select',
							name: 'month_pass_status',
							placeholder: '请选择',
							title: '当月合格状态',
							list: [
								{
									key: 0,
									value: '全部'
								},{
									key: 1,
									value: '合格'
								}, {
									key: -1,
									value: '不合格'
								},
								
							],
						},
						{
							type: "range_date",
							name: "range_date",
							title: "创建时间",
							options: {},
							start: {
								name: 'start_time'
							},
							end: {
								name: 'end_time'
							},
							hidden:false
						},
						{
							type: "month",
							name: "month",
							title: "月份",
							options: {},
							hidden:true
						},
					],
					...this.$config.form_data_seo
				},
				submit_preprocessing: {
					array_to_string: ['department','role_id']
				}
			};
		},
		async created() {
			this.$method.get_department().then(res => {
			    this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'department', 'treeData', res)
			});
			this.get_list_Count()
			this.get_status_list()
		},
		methods: {
			get_status_list(){
				getSeasonSelect().then(res=>{
					 this.form_data_seo.list[4].list =  res.data.list.map(it=>{
						 return {
									key: it.value,
									value: it.label,
								}
					 })
				})
			},
			show() {
				this.drawer_visible = true;
			},
			see_rule(data) {
				getRuleDetail({
					data: {
						id: data.rule_id
					}
				}).then(res => {
					this.see_info = res.data.list
					this.see_visible = true
				})
			},
			report() {
				let report_key = "";
				if (this.tab_index == 0) {
					report_key = "sink_frontline_day";
				} else if (this.tab_index == 1) {
					report_key = "sink_frontline_month";
				}
				this.$router.push({
					path: "/report/modify_report",
					query: {
						report_key: report_key,
						...this.$refs.list.seo_data
					}
				});
			},
			get_pass_details(item, index) {
				this.$refs.passInfo.get_details(item);
			},
			get_details(item, index) {
				this.$refs.supInfo.get_details(item);
			},
			tab_change(e) {
				let that=this
				this.tab_index = e;
				if (this.tab_index == 0) {
					this.form_data_seo.list[5].hidden=false
					this.form_data_seo.list[6].hidden=true
					this.columns_list = this.columns
					this.get_table_list = getDayList
					this.type=1
					
					this.$nextTick(() => {
						that.$refs.list.pagination.page=1
						that.$refs.list.get_list();
						that.get_list_Count()
					})
				} else {
					this.form_data_seo.list[5].hidden=true
					this.form_data_seo.list[6].hidden=false
						this.type=2
					this.columns_list = this.columns_month
					this.get_table_list = getMonthList
					this.$nextTick(() => {
						that.get_list_Count()
						that.$refs.list.pagination.current=1
						that.$refs.list.pagination.page=1
						that.$refs.list.get_list();
					})
				}
			},
			changeStatus(data){
				this.get_list_Count(data)
			},
			get_list_Count(data) {
				let info = data?data:{}
				getTopCount({
					data: {...info,type:this.type}
				}).then(res => {
					this.top_count = res.data.list
				})
			},

		}
	};
</script>

<style lang="less">
	@import url("../../assets/less/app.less");
	.right_btn{
		text-align: right;
	}
	.max_price_tips {
		margin-left: 99px;
		margin-top: -30px;
		font-size: 13px;
	}

	.float_left {
		margin-right: 8px;
	}

	.max_price_form {
		.item {
			display: flex;

			.right {
				flex: 1;

				.input_number {
					width: 100%;
				}
			}
		}
	}

	.page_content_top {
		display: flex;

		div {
			flex: 1;
			text-align: center;
		}

		div:nth-child(3) {
			cursor: pointer;
		}
	}

	.info_item {
		display: flex;
		font-size: 16px;
		line-height: 35px;

		span {
			flex: 1;
			text-align: right;
		}

		span:nth-child(2) {
			flex: 2;
			text-align: left;
			margin-left: 10px;
		}
	}
</style>