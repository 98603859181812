import req from "../methods/req"
import method from '../methods/method'

// 获取登录日志列表
export const loginLog = function (data){
    return method.get_list({
        url:'/manage/crm.business/loginLog',
        ...data
    })
}

export const onLineLog = function (data){
    return method.get_list({
        url:'/manage/crm.business/onLineLog',
        ...data
    })
}



export const getPCLogList = function (data){
    return method.get_list({
        url:'/manage/crm.system/getPCLogList',
        ...data
    })
}

