<template>
	<div>
		<TableList @list_after='list_after' @changeStatus='changeStatus' :rowSelection="rowSelection" :rowKey="'uid'"
			:scroll="{x: true}" :form_data_seo.sync="form_data_seo" :columns="columns"
			:get_table_list="this.get_table_list" :slot_table_list="['operation','department_name','goods_num']"
			ref="list" :submit_preprocessing="submit_preprocessing">
			<template slot="right_btn">

				<a-button v-auth="'change'" class="float_left"
					:disabled='(selectedRowKeys.length>0||checkedAll)?false:true' style="margin-right: 10px;"
					@click="set_add_targe()" type="primary">批量制定销售目标</a-button>
				<!-- <a-button v-auth="'change'" :disabled='(selectedRowKeys.length>0||checkedAll)?false:true' class="float_left" style="margin-right: 10px;"
					@click="set_add_targe()" type="primary">批量添加销售目标</a-button> -->
				<!-- <a-button v-auth="'change'" :disabled='(selectedRowKeys.length>0||checkedAll)?false:true' class="float_left" style="margin-right: 10px;"
					@click="reset_targe_all()" type="primary">批量重置销售目标</a-button> -->

				<export-table url="/manage/target.export/exportTargetInfo">导出</export-table>

			</template>
			<template slot="left_btn">
				<a-checkbox style='margin-left:22px;margin-top: 10px;' :checked='checkedAll' @change="onChange">
					全选
				</a-checkbox>
			</template>
			<template slot="operation" slot-scope="data">
				<a v-auth="'change'" @click="set_targe(data.record)">
					{{data.record.target_status==1?'编辑目标':'制定目标'}}
				</a>
				<a-divider v-if='data.record.target_status==1' v-auth="'change'" type="vertical" />
				<a v-auth="'change'" v-if='data.record.target_status==1' @click="reset_targe(data.record)">重置目标</a>
				<a-divider v-auth="'change'" type="vertical" v-if='data.record.target_status==1' />

				<a v-auth="'change'" v-if='data.record.target_status==1' @click="set_goods_log(data.record)">查看</a>
			</template>
			<template slot="goods_num" slot-scope="data">
				<a v-auth="'change'" v-if='data.record.goods_num>0'
					@click="set_goods_targe(data.record)">{{data.record.goods_num}}</a>
				<span v-else>{{data.record.goods_num}}</span>
			</template>

			<template slot="department_name" slot-scope="data">
				<p v-for='it in data.record.department_name'>{{it}}</p>
			</template>
		</TableList>
		<a-modal width='50%' :title="edit_type?'编辑销售目标':'添加销售目标'" :maskClosable='true' :visible="add_visible"
			@cancel='add_visible=false;material_list=[];base_info={};' @ok='add_target'>
			<div>
				<div>
					<span>目标制定月份： <a-month-picker format="YYYY-MM" v-model:value="target_month"
							placeholder="请选择月份" /></span>
				</div>
				<a-button style='margin-bottom:10px' @click="add_material()" type="primary">选择品项</a-button>
				<a-table :dataSource="material_list" :columns="columns_goods_list">
					<template slot="goods_num" slot-scope="text, record, index">
						<a-input type="number" name="num" v-model:value="record.goods_num" />
					</template>
					<template slot="op" slot-scope="text, record, index">
						<span style='color:#FF3B30;' @click='del_goods(record,index)'>删除</span>
					</template>
				</a-table>
			</div>
		</a-modal>
		<a-modal width='50%' title="品项" :maskClosable='true' :visible="see_goods_info"
			@cancel='see_goods_info=false;see_material_list=[]' @ok='see_goods_info=false;see_material_list=[]'>
			<div>
				<a-table :dataSource="see_material_list" :columns="see_columns_goods_list">
				</a-table>
			</div>
		</a-modal>


		<a-modal width='50%' title="查看修改记录" :maskClosable='true' :visible="see_log" @cancel='see_log=false;'
			@ok='see_log=false;'>
			<a-table :slot_table_list="['goods_num']" :dataSource="see_log_list" :columns="see_columns_log_list">
				<template slot="goods_num" slot-scope="text,record,index">
					<a v-auth="'change'" v-if='record.goods_num>0'
						@click="set_goods_targe(record)">{{record.goods_num}}</a>
					<span v-else>{{record.goods_num}}</span>
				</template>

			</a-table>
		</a-modal>


		<!--  -->
		<SelectMaterialGood ref='material' chooseType='checkbox' @submit="select_material_submit"
			:visible.sync="material_visible">
		</SelectMaterialGood>
	</div>

</template>

<script>
	// @ is an alias to /src
	import EditPop from "@/components/EditPop";
	import SelectMaterialGood from "@/components/SelectMaterialGood";
	import Form from "@/components/Form";
	import TableList from "@/components/TableList";

	import {
		getSalesTargetUserList,
		saveTarget,
		getSalesTargetDetail,
		resetSalesTarget,
		getUserTargetRecord
	} from "@/api/order";
	import moment from 'moment';

	import {
		message
	} from 'ant-design-vue'
	let columns_goods_list = [{
			title: "品项",
			dataIndex: "goods_title",
		},
		{
			title: "销售目标数量",
			dataIndex: "goods_num",
			scopedSlots: {
				customRender: "goods_num",
			},

		},
		{
			title: "操作",
			dataIndex: "op",
			scopedSlots: {
				customRender: "op",
			},

		},

	]
	let see_columns_goods_list = [{
			title: "品项",
			dataIndex: "goods_title",
		},
		{
			title: "销售目标(件)",
			dataIndex: "goods_num",
		},
		{
			title: "已销售（件）",
			dataIndex: "sales_num",
		},
		{
			title: "达成率",
			dataIndex: "sales_rate",
		},

	]
	let columns = [{
			title: "ID",
			dataIndex: "uid",

		},
		{
			title: "姓名",
			dataIndex: "username",
		},
		{
			title: "部门",
			dataIndex: "department_name",
			scopedSlots: {
				customRender: "department_name"
			},
		},
		{
			title: "销售目标",
			dataIndex: "target_status_name",

		},
		{
			title: "包含品项",
			dataIndex: "goods_num",
			scopedSlots: {
				customRender: "goods_num"
			},
		},
		{
			title: "总体目标（件）",
			dataIndex: "total_num",

		},
		{
			title: "已销售（件）",
			dataIndex: "sales_num",

		},
		{
			title: "销售目标",
			dataIndex: "sales_rate",

		},

		{
			title: "操作",
			scopedSlots: {
				customRender: "operation"
			},
			fixed: 'right'
		},
	];

	let see_columns_log_list = [{
			title: "修改人",
			dataIndex: "username",
		},
		{
			title: "创建时间",
			dataIndex: "create_time",
		},
		{
			title: "包含品项",
			dataIndex: "goods_num",
			scopedSlots: {
				customRender: "goods_num"
			},
		},
		{
			title: "总体销售目标（件）",
			dataIndex: "total_num",
		},
		{
			title: "已销售目标（件）",
			dataIndex: "sales_num",
		},
		{
			title: "达成率",
			dataIndex: "sales_rate",
		},

	]


	export default {
		name: "Index",
		components: {
			EditPop,
			SelectMaterialGood,
			Form,
			TableList,
		},
		data() {
			return {
				where: {},
				see_material_log_list: [],
				get_table_list2: getUserTargetRecord,
				see_log: false,
				see_log_list: [],
				see_log_goods_vis: false,
				see_log_goods: [],
				see_columns_log_list,
				see_material_list: [],
				see_goods_info: false,
				tager_month: '',
				base_info: {},
				material_visible: false,
				columns_goods_list,
				material_list: [],
				add_select_customer: {},
				add_pid_visible: false,
				edit_type: 1,
				get_table_list: getSalesTargetUserList,
				submit_preprocessing: {
					array_to_string: ['department', 'customer_type']
				},
				form: this.$form.createForm(this, {
					name: "advanced_search"
				}),
				uids: '',
				see_columns_goods_list,
				selectedRowKeys: [],
				selectedRows: [],
				columns,
				visible: false,
				add_visible: false,
				add_time: '',
				checkedAll: false,
				form_data_seo: {
					list: [{
							type: "tree-select",
							name: "department",
							title: "部门",
							options: {},
							treeData: [],
							multiple: true
						}, {
							type: "text",
							name: "keyword",
							title: "员工名称",
							placeholder: '员工名称',
							options: {}
						},

						{
							type: "select",
							name: "target_status",
							title: "销售目标",
							options: {},
							list: [{
									key: 1,
									value: "已制定"
								},
								{
									key: 2,
									value: "未制定"
								},
							]
						},
						{
							type: "month",
							name: "target_month",
							title: "目标月份",
							options: {
								initialValue: this.$moment(new Date(), 'YYYY/MM')
							}

						},

					],
					...this.$config.form_data_seo
				},
				form_data: {
					title: '添加套餐',
					list: [{
						type: 'slot',
						name: 'goods',
						title: '销售目标品项',
						options: {},
					}, ]
				},

				goods_model: '',
				send_img: false,
				edit_type: false,
				goods_code_list: [],
				target_month: '',
				template: [],
				del_list: []
			};
		},
		async created() {
			this.target_month = this.$moment(new Date(), 'YYYY/MM')
			let query = this.$route.query;
			this.$method.get_department().then(res => {
				this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'department',
					'treeData', res)
			});
		},
		computed: {

			rowSelection() {
				const {
					selectedRowKeys
				} = this;
				return {
					selectedRowKeys,
					onChange: (selectedRowKeys, selectedRows) => {
						// 由于selectedRows在翻页后不会保留之前选中的数据，所以需要在此处进行额外处理
						// 在这本次selectedRows之前已经选中了的用户
						let prevSelectedRows = this.selectedRows;
						// 将变化前的selectedRows与现在的selectedRows进行结合
						let selectedCustomerInfo = [...prevSelectedRows, ...selectedRows];
						this.selectedRowKeys = selectedRowKeys;
						this.uids = selectedRowKeys.join(',')
					},
					onSelect: (record, selected, selectedRows, nativeEvent) => {

						if (!selected) {
							if (this.del_list.indexOf(record.uid) < 0) {
								this.del_list.push(record.uid)
							}
						} else {
							this.del_list.splice(this.del_list.indexOf(record.uid), 1)
						}
					},
					hideDefaultSelections: true,
				};
			}
		},
		methods: {
			onChange(e) {
				this.checkedAll = e.target.checked
				if (e.target.checked) {
					this.selectedRowKeys = this.template
				} else {
					this.selectedRowKeys = []
					this.del_list = []
				}
			},
			list_after(res) {
				this.template = this.template.concat(res.res.data.list.data.map(it => {
					return it.uid
				}))
				if (this.checkedAll) {
					this.selectedRowKeys = this.template
				}
				if (this.del_list.length > 0) {
					this.del_list.forEach(it => {
						if (this.selectedRowKeys.indexOf(it) > -1) {
							this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(it), 1)
						}
						if (this.template.indexOf(it) > -1) {
							this.template.splice(this.template.indexOf(it), 1)
						}
					})
				}
			},
			changeStatus(e) {
				this.target_month = e.target_month
				this.department = e.department
				if (this.department) {
					this.base_info.department = this.department
					this.base_info.type = 'all'
				}
				this.template = []
			},
			set_goods_log(data) {
				this.where.uid = data.uid
				this.where.target_month = data.target_month
				getUserTargetRecord({
					data: {
						...this.where
					}
				}).then(res => {
					this.see_log_list = res.data.list.data
				})
				this.see_log = true
			},
			del_goods(data, index) {
				this.material_list.splice(index, 1)
			},
			set_goods_targe(data) {
				getSalesTargetDetail({
					data: {
						target_month: data.target_month,
						uid: data.uid,
						target_id: data.target_id,
						type: 2

					}
				}).then(res => {
					this.see_material_list = res.data.list.goods_info
					this.see_goods_info = true
				})
			},
			reset_targe_all() {
				resetSalesTarget({
					data: {
						"target_month": this.target_month, //月份
						"uids": this.uids, //用户id
					}
				}).then(res => {
					this.uids = ''
					this.selectedRows = []
					this.selectedRowKeys = []
					this.$refs.list.get_list();
				})
			},
			reset_targe(data) {
				resetSalesTarget({
					data: {
						"target_month": data.target_month, //月份
						"uids": data.uid, //用户id
					}
				}).then(res => {
					this.$refs.list.get_list();
				})
			},
			add_target() {
				saveTarget({
					data: {
						"target_month": this.target_month ? moment(this.target_month).format('YYYY-MM') : '', //月份
						"uids": this.base_info.uid, //用户id
						"goods_info": this.material_list,
						type: this.checkedAll ? 'all' : this.base_info.type,
						department_id: this.base_info.type == 'all' && this.base_info.department ? this.base_info
							.department : '',
					},
					info: true
				}).then(res => {
					this.selectedRows = []
					this.base_info = {}
					this.selectedRowKeys = []
					this.material_list = []
					this.base_info = {}
					this.add_visible = false
					this.checkedAll = false
					this.del_list = []
					this.template = []
					this.uids = ''
					this.$refs.list.get_list();
				})
			},
			select_material_submit({
				data
			}) {
			
				this.material_list = data.map(info => {
					info.goods_id = info.id
					info.goods_num = info.goods_num
					info.goods_price = info.goods_price
					return info
				})
			},
			add_material() {
				let that = this
				this.material_visible = true
				this.$nextTick(() => {
					that.$refs.material.selectedRowKeys = that.material_list.map(it => {
						return it.id
					})
					that.$refs.material.temp_list = that.material_list.length > 0 ? JSON.parse(JSON.stringify(that
						.material_list)) : []
				})
			},

			set_add_targe(type) {
				if (this.checkedAll) {
					this.base_info.type = 'all'
					this.base_info.uid = this.del_list.join(',')

				} else {
					this.base_info.type = 'part'
					this.base_info.uid = this.uids
				}

				this.edit_type = false
				this.add_visible = true
			},
			set_targe(data) {
				this.base_info = data
				this.target_month = data.target_month ? data.target_month : this.target_month
				this.checkedAll = false
				if (data.target_status == 0) {
					this.edit_type = false
					this.add_visible = true
				} else {
					getSalesTargetDetail({
						data: {
							target_month: data.target_month,
							uid: data.uid,
							type: 1,
							target_id: data.target_id,

						}
					}).then(res => {
						this.material_list = res.data.list.goods_info.map(it => {
							it.id = it.goods_id
							return it
						})

						this.edit_type = true
						this.add_visible = true

					})
				}


			},
		}
	};
</script>

<style lang="less">
	@import url("../../assets/less/app.less");

	.drawer_title {
		padding: 15px 0;
	}

	.use_status {
		display: flex;
		align-items: center;

		.dot {
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background: @success-color;
			margin-right: 8px;
		}

		.red {
			background: @error-color;
		}
	}

	.input_item {
		display: flex;
		align-items: center;
		margin-top: 14px;

		.text {
			white-space: nowrap;
			font-size: 14px;
			margin-right: 4px;
		}
	}

	.tips {
		margin-top: 8px;
		color: @error-color;
		font-size: 14px;
	}

	.code_box {
		display: flex;
		align-items: center;

		div {
			flex: 1;
		}
	}
</style>